import React from 'react'
import animateScroll from 'react-scroll/modules/mixins/animate-scroll'
import ReactSVG from 'react-svg'

import ArrowUp from '../../../../images/arrow-up.svg'

import styles from './back-to-top.css'

function scrollToTop() {
  const options = {
    duration: 1000,
    smooth: 'easeOutCubic'
  }

  return animateScroll.scrollToTop(options)
}

function BackToTop() {
  return (
    <div
      className={styles.backToTopWrapper}
      onClick={scrollToTop}
    >
      <a
        className={styles.backToTopLink}
        data-lbx-e2e="Back To Top"
      >
        BACK TO TOP
      </a>
      <ReactSVG
        src={ArrowUp}
        beforeInjection={(svg) => {
          svg.setAttribute('class', styles.arrowUp)
        }}
      />
    </div>
  )
}

export default BackToTop
